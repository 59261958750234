const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '475px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};

const background = {
    "background": '#CEE3F1',
    "backgroundLight": '#CEE3F1',
};

export const theme = {
    spring: {
        "base": '#364417',
        "mid": '#67751E',
        "light": '#A4B745',
        "lighter": '#FEFFBA',
        "gradient": 'linear-gradient(180deg, #67751E 0%, #364417 100%)',
        "backgroundImage": 'url(/images/header-backgrounds/bg-spring.jpg)',
        "backgroundImageWebp": 'url(/images/header-backgrounds/bg-spring.webp)',
        "backgroundPosition": "50% 80%",
        ...background
    },
    summer: {
        "base": '#500000',
        "mid": '#8B2C3E',
        "light": '#C35A6E',
        "lighter": '#FFBEBE',
        "gradient": 'linear-gradient(180deg, #8B2C3E 0%, #500000 100%)',
        "backgroundImage": 'url(/images/header-backgrounds/bg-summer.jpg)',
        "backgroundImageWebp": 'url(/images/header-backgrounds/bg-summer.webp)',
        "backgroundPosition": "50% 20%",
        ...background
    },
    autumn: {
        "base": '#461608',
        "mid": '#7C401A',
        "light": '#B87A27',
        "lighter": '#FFEA90',
        "gradient": 'linear-gradient(180deg, #7C401A 0%, #461608 100%)',
        "backgroundImage": 'url(/images/header-backgrounds/bg-autumn.jpg)',
        "backgroundImageWebp": 'url(/images/header-backgrounds/bg-autumn.webp)',
        "backgroundPosition": "50% 80%",
        ...background
    },
    winter: {
        "base": '#23304A',
        "mid": '#1A6679',
        "light": '#4090A5',
        "lighter": '#CDE7EB',
        "gradient": 'linear-gradient(180deg, #1A6679 0%, #23304A 100%)',
        "backgroundImage": 'url(/images/header-backgrounds/bg-winter.jpg)',
        "backgroundImageWebp": 'url(/images/header-backgrounds/bg-winter.webp)',
        "backgroundPosition": "50% 30%",
        ...background
    }
};
