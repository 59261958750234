import styled from 'styled-components';
import { Link } from "react-router-dom";
import { device } from './helpers';

export const Button = styled.button`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -.19px;
    padding: 9.5px 20px 11.5px;
    border-radius: 5px;
    border: 1px solid transparent;
`;

export const Checkbox = styled.input.attrs(props => ({
    type: "checkbox",
}))`
    appearance: auto;
    width: 33px;
    height: 33px;
    border-radius: 0;
    margin: 0 20px 0 0;
    opacity: 0;
`;

export const HomeLink = styled(Link)`
    text-decoration: none;
    padding: 5px 35px 6px;
    color: white;
    background-color: ${props => props.theme.mid};
    bottom: 10px;
    left: 50%;
    transition: .4s ease transform;
    box-shadow: 0 2px 8px 0 rgba(000,000,000,.5);
    font-weight: bold;
    border-radius: 5px;

    &:hover {
        background-color: ${props => props.theme.base};
    }
`;

export const ModalLayout = styled.div`
    position: fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.40);
    padding: 10px;
    z-index: 100000;

    @media ${device.tablet} {
        padding: 15px;
    }
`;

export const ModalWrapper = styled.div`
    background-color: ${props => props.theme.base};
    background-image: ${props => props.theme.gradient};
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    color: white; 
    max-width: 550px;
    padding: 25px;
    text-align: center;

    h2 {
        margin-top: 0;
        font-size: 24px;
        line-height: 28px;

        @media ${device.tablet} {
            font-size: 36px;
            line-height: 40px;
        }

        @media ${device.laptop} {
            font-size: 32px;
            line-height: 36px;
        }
    }

    a {
      word-wrap: break-word;
      word-break: break-word;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 16px;

        &:last-of-type {
            margin: 0 0 25px;
        }
        
        @media ${device.tablet} {
            font-size: 26px;
            line-height: 30px;

            &:last-of-type {
                margin: 0 0 35px;
            }
        }

        @media ${device.laptop} {
            font-size: 24px;
            line-height: 28px;
        }
    }

    @media ${device.tablet} {
        padding: 35px 60px;
    }
`;

export const ModalButton = styled(Button)`
    background: #FFFFFF;
    font-size: 15px;
    color: #3F2D14;
    font-weight: bold;
    padding: 10px 30px;
`;
