import styled from 'styled-components';
import { device } from '../../styles/helpers';

export const IntroLogo = styled.div`
  
    margin-bottom: 20px;

    img {
        max-width: 178px;
    }

    @media ${device.tablet} {
        margin-bottom: 30px;

        img {
            max-width: 267px;
        }
    }
`;

