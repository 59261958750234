import styled from 'styled-components';

export const MainStyle = styled.div`
  background-color: ${props => props.theme.background};
  display: flex;
  flex-direction: column;
  align-items:  stretch;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  .results & {
    background-color: ${props => props.theme.backgroundLight};
  }
`;