import { createSelector } from 'reselect';

const getSeason = (state) => state.state.season;

export const selectSeason = createSelector(getSeason, (season) => season);

const getType = (state) => state.state.type;

export const selectType = createSelector(getType, (type) => type);

const getGeoJson = (state) => state.state.geojson;

export const selectGeoJson = createSelector(getGeoJson, (geojson) => geojson);

const getBg = (state) => state.state.bg;

export const selectBg = createSelector(getBg, (bg) => bg);

const getTotal = (state) => state.state.total;

export const selectTotal = createSelector(getTotal, (total) => total);