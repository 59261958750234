import { combineReducers } from 'redux';
import stateReducer from './state/reducer';

export default function createReducer() {
    const rootReducer = combineReducers({
        state: stateReducer,
    });

    return rootReducer;
}
