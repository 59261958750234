import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import createReducer from './reducers';
import * as Sentry from "@sentry/react";

export default function configureStore(preloadedState) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer, sentryReduxEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(
        createReducer(),
        preloadedState,
        composedEnhancers
    );

    return store;
}
