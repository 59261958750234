import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../styles/helpers';

export const Bar = styled.footer`
    background-color: black;
    color: white;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    line-height: 17px;

    @media ${device.mobileM} { 
        font-size: 14px;
        line-height: 18px;
    }

    @media ${device.mobileL} { 
        flex-direction: row;
        font-size: 13px;
        line-height: 16px;
    }

    @media ${device.tablet} { 
        padding: 6px 15px;
    }
    
    .Footer_links {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        a {
            padding: 0 5px;
            border-left: 1px solid white;

            &:first-child {
                border: 0;
            }
        }

        @media ${device.mobileL} { 
            margin-bottom: 0;
        }
    }
`;

export const StyledLink = styled.a`
    padding: 0 5px;
    border-left: 1px solid white;
    color: white;
    text-decoration: none;

    &:first-child {
        border: 0;
    }

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledButton = styled.button`
    padding: 0 5px;
    border: 0;
    border-left: 1px solid white;
    color: white;
    text-decoration: none;
    margin: 0;
    font-size: 13px;
    line-height: 16px;

    &:first-child {
        border: 0;
    }

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledRouterLink = styled(Link)`
    padding: 0;
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;