const postcodeData = [
    { Postcode: 'AB', Name: 'Aberdeen' },
    { Postcode: 'AL', Name: 'St Albans' },
    { Postcode: 'B', Name: 'Birmingham' },
    { Postcode: 'BA', Name: 'Bath' },
    { Postcode: 'BB', Name: 'Blackburn' },
    { Postcode: 'BD', Name: 'Bradford' },
    { Postcode: 'BH', Name: 'Bournemouth' },
    { Postcode: 'BL', Name: 'Bolton' },
    { Postcode: 'BN', Name: 'Brighton' },
    { Postcode: 'BR', Name: 'Bromley' },
    { Postcode: 'BS', Name: 'Bristol' },
    { Postcode: 'BT', Name: 'Belfast' },
    { Postcode: 'CA', Name: 'Carlisle' },
    { Postcode: 'CB', Name: 'Cambridge' },
    { Postcode: 'CF', Name: 'Cardiff' },
    { Postcode: 'CH', Name: 'Chester' },
    { Postcode: 'CM', Name: 'Chelmsford' },
    { Postcode: 'CO', Name: 'Colchester' },
    { Postcode: 'CR', Name: 'Croydon' },
    { Postcode: 'CT', Name: 'Canterbury' },
    { Postcode: 'CV', Name: 'Coventry' },
    { Postcode: 'CW', Name: 'Crewe' },
    { Postcode: 'DA', Name: 'Dartford' },
    { Postcode: 'DD', Name: 'Dundee' },
    { Postcode: 'DE', Name: 'Derby' },
    { Postcode: 'DG', Name: 'Dumfries' },
    { Postcode: 'DH', Name: 'Durham' },
    { Postcode: 'DL', Name: 'Darlington' },
    { Postcode: 'DN', Name: 'Doncaster' },
    { Postcode: 'DT', Name: 'Dorchester' },
    { Postcode: 'DY', Name: 'Dudley' },
    { Postcode: 'E', Name: 'East London' },
    { Postcode: 'EC', Name: 'East Central London' },
    { Postcode: 'EH', Name: 'Edinburgh' },
    { Postcode: 'EN', Name: 'Enfield' },
    { Postcode: 'EX', Name: 'Exeter' },
    { Postcode: 'FK', Name: 'Falkirk' },
    { Postcode: 'FY', Name: 'Blackpool' },
    { Postcode: 'G', Name: 'Glasgow' },
    { Postcode: 'GL', Name: 'Gloucester' },
    { Postcode: 'GU', Name: 'Guildford' },
    { Postcode: 'GY', Name: 'Guernsey' },
    { Postcode: 'HA', Name: 'Harrow' },
    { Postcode: 'HD', Name: 'Huddersfield' },
    { Postcode: 'HG', Name: 'Harrogate' },
    { Postcode: 'HP', Name: 'Hemel Hempstead' },
    { Postcode: 'HR', Name: 'Hereford' },
    { Postcode: 'HS', Name: 'Hebrides' },
    { Postcode: 'HU', Name: 'Hull' },
    { Postcode: 'HX', Name: 'Halifax' },
    { Postcode: 'IG', Name: 'Ilford' },
    { Postcode: 'IM', Name: 'Isle of Man' },
    { Postcode: 'IP', Name: 'Ipswich' },
    { Postcode: 'IV', Name: 'Inverness' },
    { Postcode: 'JE', Name: 'Jersey' },
    { Postcode: 'KA', Name: 'Kilmarnock' },
    { Postcode: 'KT', Name: 'Kingston upon Thames' },
    { Postcode: 'KW', Name: 'Kirkwall' },
    { Postcode: 'KY', Name: 'Kirkcaldy' },
    { Postcode: 'L', Name: 'Liverpool' },
    { Postcode: 'LA', Name: 'Lancaster' },
    { Postcode: 'LD', Name: 'Llandrindod Wells' },
    { Postcode: 'LE', Name: 'Leicester' },
    { Postcode: 'LL', Name: 'Llandudno' },
    { Postcode: 'LN', Name: 'Lincoln' },
    { Postcode: 'LS', Name: 'Leeds' },
    { Postcode: 'LU', Name: 'Luton' },
    { Postcode: 'M', Name: 'Manchester' },
    { Postcode: 'ME', Name: 'Medway' },
    { Postcode: 'MK', Name: 'Milton Keynes' },
    { Postcode: 'ML', Name: 'Motherwell' },
    { Postcode: 'N', Name: 'North London' },
    { Postcode: 'NE', Name: 'Newcastle upon Tyne' },
    { Postcode: 'NG', Name: 'Nottingham' },
    { Postcode: 'NN', Name: 'Northampton' },
    { Postcode: 'NP', Name: 'Newport' },
    { Postcode: 'NR', Name: 'Norwich' },
    { Postcode: 'NW', Name: 'North West London' },
    { Postcode: 'OL', Name: 'Oldham' },
    { Postcode: 'OX', Name: 'Oxford' },
    { Postcode: 'PA', Name: 'Paisley' },
    { Postcode: 'PE', Name: 'Peterborough' },
    { Postcode: 'PH', Name: 'Perth' },
    { Postcode: 'PL', Name: 'Plymouth' },
    { Postcode: 'PO', Name: 'Portsmouth' },
    { Postcode: 'PR', Name: 'Preston' },
    { Postcode: 'RG', Name: 'Reading' },
    { Postcode: 'RH', Name: 'Redhill' },
    { Postcode: 'RM', Name: 'Romford' },
    { Postcode: 'S', Name: 'Sheffield' },
    { Postcode: 'SA', Name: 'Swansea' },
    { Postcode: 'SE', Name: 'South East London' },
    { Postcode: 'SG', Name: 'Stevenage' },
    { Postcode: 'SK', Name: 'Stockport' },
    { Postcode: 'SL', Name: 'Slough' },
    { Postcode: 'SM', Name: 'Sutton' },
    { Postcode: 'SN', Name: 'Swindon' },
    { Postcode: 'SO', Name: 'Southampton' },
    { Postcode: 'SP', Name: 'Salisbury' },
    { Postcode: 'SR', Name: 'Sunderland' },
    { Postcode: 'SS', Name: 'Southend-on-Sea' },
    { Postcode: 'ST', Name: 'Stoke-on-Trent' },
    { Postcode: 'SW', Name: 'South West London' },
    { Postcode: 'SY', Name: 'Shrewsbury' },
    { Postcode: 'TA', Name: 'Taunton' },
    { Postcode: 'TD', Name: 'Tweeddale' },
    { Postcode: 'TF', Name: 'Telford' },
    { Postcode: 'TN', Name: 'Tunbridge Wells' },
    { Postcode: 'TQ', Name: 'Torquay' },
    { Postcode: 'TR', Name: 'Truro' },
    { Postcode: 'TS', Name: 'Teesside' },
    { Postcode: 'TW', Name: 'Twickenham' },
    { Postcode: 'UB', Name: 'Southall' },
    { Postcode: 'W', Name: 'West London' },
    { Postcode: 'WA', Name: 'Warrington' },
    { Postcode: 'WC', Name: 'West Central London' },
    { Postcode: 'WD', Name: 'Watford' },
    { Postcode: 'WF', Name: 'Wakefield' },
    { Postcode: 'WN', Name: 'Wigan' },
    { Postcode: 'WR', Name: 'Worcester' },
    { Postcode: 'WS', Name: 'Walsall' },
    { Postcode: 'WV', Name: 'Wolverhampton' },
    { Postcode: 'YO', Name: 'York' },
    { Postcode: 'ZE', Name: 'Lerwick' }];

export default postcodeData;