import styled from 'styled-components';
import { device } from '../../styles/helpers';
import { ModalWrapper } from '../../styles/sharedStyles';

export const CookiesWrapper = styled(ModalWrapper)`

    h2 {
        margin-top: 0;
        font-size: 24px;
        line-height: 28px;

        @media ${device.tablet} {
            font-size: 36px;
            line-height: 40px;
        }

        @media ${device.laptop} {
            font-size: 32px;
            line-height: 36px;
        }
    }
  
    p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 16px;
        
        @media ${device.tablet} {
            font-size: 18px;
            line-height: 22px;
        }

        @media ${device.laptop} {
            font-size: 16px;
            line-height: 20px;
        }
    }
`;

